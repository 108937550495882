.checkout {
  &.active-panel-shipping {
    .field-container {
      &.field-container--grid {
        &.city-state-zip {
          .form-item {
            &.phone-1 {
              width: 100%;
            }
            &.city {
              width: 48%;
              margin-#{$rdirection}: 2%;
            }
            &.postal-code {
              width: 48%;
              float: right;
              margin-#{$rdirection}: 0%;
            }
          }
        }
      }
    }
  }
}
