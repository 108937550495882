.site-container {
  .profile-page {
    .profile-info {
      .field-container {
        .gender {
          label {
            display: block;
          }
          .picker-radio {
            display: inline-block;
          }
        }
      }
    }
  }
  .address-book-page__content {
    .address-item__info,
    .payment-item__address {
      word-break: break-word;
    }
  }
  .order-details-page {
    .order-details {
      .shipping_address_container,
      .billing_address_container {
        word-break: break-word;
      }
    }
  }
}
