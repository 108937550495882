.front {
  .site-container {
    .basic-carousel-item {
      .wrapper {
        .basic-carousel-item-copy__header-wrapper {
          min-height: 140px;
        }
      }
    }
  }
}
